.you_are_nothing {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 22px;
}

.typing {
  width: 40ch;
  animation: typing 4s steps(22), blink 0.75s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  color: chocolate;
  transform: translate3d(0, 0, 0);
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
