.soAboveSoBelow {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  animation: glitch 450ms linear infinite;
  transform: translate3d(0, 0, 0);
}

.soAboveSoBelow span {
  font-size: 90vh;
  color: violet;
  opacity: 0.25;
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(8px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-8px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

.soAboveSoBelow:before,
.soAboveSoBelow:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.soAboveSoBelow:before {
  animation: glitchTop 500ms linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.soAboveSoBelow:after {
  animation: glitchBotom 350ms linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}
