body {
  margin: 5px;
  padding: 12px;
  min-height: calc(100vh - 54px);
  font-family: "DM Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  max-width: 600px;
  text-shadow: 0.06rem 0 0.06rem #ea36af, -0.125rem 0 0.06rem #75fa69;
  letter-spacing: 0.125em;
  animation-duration: 0.01s;
  animation-name: textflicker;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background-color: #111;
}
@keyframes textflicker {
  from {
    text-shadow: 1px 0 0 #ea36af, -2px 0 0 #75fa69;
  }
  to {
    text-shadow: 2px 0.5px 2px #ea36af, -1px -0.5px 2px #75fa69;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.hail {
  animation: 950ms linear 1s infinite alternate blinker;
}

/* width */
::-webkit-scrollbar {
  width: 30px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1e1e1e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(0, 13%, 19%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* utility shit */
.hide {
  display: none;
}
